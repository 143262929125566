

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.invalid-feedback {
  display: inline-block !important;
}

/**
 * icono cuando está cargando un request
 */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  border-right: 16px solid white;
  border-bottom: 16px solid #3498db;
  border-left: 16px solid white;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  z-index:999999999999;
  left: 50%;
  top: 40%;
}
#mask {
	opacity:0.3; position:fixed; width:100%; height:100%; z-index:999999;top:0;background: black;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}/* FIN LOADER */

/* ANCHO CONTENEDOR DATEPIKER, AJUSTE 100% A LA COLUMNA BOOTSTRAP*/
.react-datepicker-wrapper {
  width: 100%;
}
/* ANCHO CONTENEDOR DATEPIKER */
.formato-hora .react-datepicker {
	width: 328px !important;
}
#boton-fecha .c-icon-sm{
  margin: 0rem 0 !important;
}

.form-group {
    margin-bottom: 0.4rem !important;
}
/* Carga de archivos */
.dzu-dropzone {
  margin-bottom: 10px !important;
  min-height: 40px !important;
  border-color: #39f !important;
  border-radius: .3rem !important;
  overflow: inherit !important;
}
.dzu-dropzone:hover {
  background: #3999ff;
}
.dzu-dropzone>.dzu-inputLabel:hover {
  color: white;
}
.dzu-inputLabel {
  margin: 0px !important;
  font-size: 14px !important;
}
/* FIN Carga de archivos */

/* botones context menu */
.react-contextmenu-item svg{
  color: black !important;
}
.react-contextmenu-item--selected svg{
  color: white !important;
}
/* FIN botones context menu */
.btn-success svg {
  color: white !important;
}
.btn-secondary svg {
  color: black !important;
}
.card-header:first-child {
  font-weight: 700;
}
.card-archivo .card-footer {
  padding: 0.6rem 0.2rem !important;
}
.card-archivo input {
  border: 0px !important;
}
.card-archivo .card-body {
  background: #f5f5f5 !important;
}
.card-archivo .card-footer {
  background: #ffffff;
}
.card-archivo img {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%) !important;
}
.card-archivo:hover {
  border-color:  #c5c5c5 !important;
  box-shadow: 10px 5px 10px 5px #c5c5c5;
  cursor: pointer;
}

/* MODAL PREVIEW ARCHIVOS */
.pg-viewer-wrapper .document-container, .pdf-canvas canvas {
  width: 100% !important;
}
/* FIN MODAL PREVIEW ARCHIVOS */

.form-label {
  margin-bottom: 0rem !important;
}

/* MENU LATERAL */
html:not([dir="rtl"]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 53px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color:#fff;
}
.sidebar-nav .nav-link{
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color:#fff !important;
}
.sidebar-nav .nav-icon {
  height: 1rem !important;
}
.header-nav .nav-link {
  font-size: .875rem;
}
/* FIN MENÚ LATERAL */

/* BOTONES */
.btn-success {
  color: #ffffff !important;
}
/* FIN BOTONES */

/* bug tabs para que no ocupen espacio cuando están ocultos */
.fade:not(.show) {
  height: 0;
  overflow: hidden;
}
/* bug tabs para que no ocupen espacio cuando están ocultos */

/* clase para textos largos en contenedor react-data-grid, para LongTextFormatter2 */
.cont-longtext {
  display: table !important;
}
.subestrategia2-4 { /* PARA LA SUBESTRATEGIA 2.4 COLOR DE FONDO */
  background: #fbbcbc !important;
}

/* Colores para estados investigación */
.std-finalizado {
  background: #baf3c5 !important;
}
.std-enproceso {
  background: #edf5bc !important;
}
.std-ampliaciondeplazo {
  background: #e7f14f !important;
}
.std-informependiente {
  background: #f1c6bb !important;
}
.std-sininformacion {
  background: #cecece !important;
}
/* FIN REACT-DATA-GRID */

/* ocultar menú lateral mediante permisos */
.ocultar-menu {
  display: none;
}
/* FIN ocultar menú lateral mediante permisos */

